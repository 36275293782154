import React, { useState } from 'react';
// import { useFirebaseApp } from 'firebase';
import { auth, db  } from '../firebase/firebaseConfig';
import { setDoc, doc, getDocs, getDoc, collection, query, where } from "firebase/firestore";
import { createUserWithEmailAndPassword, signInWithEmailAndPassword  } from "firebase/auth";
//toat
import Message from '../components/Message.jsx';
import 'react-toastify/dist/ReactToastify.css';
import '../styles/login.css';


function Login(props) {
  const {user, setUser } = props;
  
  const [checked, setChecked] = useState(false);

  const [emailLogin, setEmailLogin] = useState('');
  const [passLogin, setPassLogin] = useState('');

  // const [nameRegister, setNameRegister] = useState('');
  const [emailRegister, setEmailRegister] = useState('');
  const [passRegister, setPassRegister] = useState('');
  const [userName, setUserName] = useState('');


  function login() {
    Message('No puedes logearte, bacotips iniciara el dia 20 de abril.', 'void', 'warning');
    return;
    if(user) {
      Message('Ya has iniciado sesion, por favor recargue la pagina.');
     return;
    }

    if(emailLogin === '' || passLogin === '') {
      Message('Por favor ingrese su correo y contraseña.', 'void', 'warning');
      return;
    }

    signInWithEmailAndPassword(auth, emailLogin, passLogin)
      .then((userCredential) => {
        getDoc(doc(collection(db, 'users'), userCredential.user.uid)).then(doc => {
          console.log(doc.data());
          userCredential.user.profile = doc.data();
          Message('Bienvenid@ ' + userCredential.user.profile.userName, userCredential.user.profile.userName, 'success');
          
          setUser(userCredential.user);
        });
      })
      .catch((error) => {
        if(error.code === 'auth/wrong-password') {
          Message('La contraseña es incorrecta.', 'wrong-password', 'warning');
          return;
        }
        
        if(error.code === 'auth/user-not-found') {
          Message('El usuario no existe.', 'user-not-found', 'warning');
          return;
        }

        Message('Ocurrio un error, por favor intente nuevamente.', 'error', 'error');
      });
  }

  const register = async () => {
    Message('No puedes create una cuenta aun, bacotips iniciara el dia 20 de abril.', 'void', 'warning');
    return;
    if(userName.length < 6) {
      Message('Debe ingresar un nombre de usuario con al menos 6 caracteres.', 'user-name-length', 'warning');
      return;
    }
    // verificar si ya existe el nombre de usuario con where
    const q = query(collection(db, "users"), where("userName", "==", userName));
    const exist = (await getDocs(q)).size > 0;

    if(exist) {
      Message('El nombre de usuario ya existe.', 'user-name-exists', 'warning');
      return;
    }

    createUserWithEmailAndPassword(auth, emailRegister, passRegister)
      .then((res) =>{
        Message('Bienvenid@ ' + userName, 'register-success', 'success');
        setEmailRegister('');
        setPassRegister('');
        // crear usuario en firestore con el id del usuario
        
        const parent = 'null';
        const data = {
          name: userName,
          userName: userName, // autoicrementable
          photo: {
            uri: '/profiles/avatar-default.png', // url de la foto
            fileId: 'default',
            transformations: []
          },
          banner: {
            uri: '/banners/banner-default.jpg', // url del banner
            fileId: 'default',
            transformations: []
          },
          description: '',
          phone: '',
          referralLink: '', // generar automaticamente
          referralUsed: 0,
          referralParent: parent, // uid o referral del parent
          credits: 10,
          verified: false,
          createdAt: new Date(),
          lastActivity: new Date(),
          favorites: [],
          likes: [],
          notifications: [],
        };

        setDoc(doc(db, 'users', res.user.uid), data);
      })
      .catch((err) => {
        if(err.code === "auth/weak-password") {
          Message('La contraseña es muy debil,  debe tener una longitud minima de 6 caracteres.', 'weak-password', 'warning');
        } else if(err.code === "auth/email-already-in-use") {
          Message('El correo ya esta en uso.', 'email-already-in-use', 'warning');
        } else if(err.code === "auth/invalid-email") {
          Message('El correo no es valido.', 'invalid-email', 'warning');
        } else {
          Message('Ocurrio un error, por favor revise los campos de correo y contraseña.', 'register-error', 'warning');
          console.log(err);
        }
      });
  }

  return (
    <div className="modal-container" id="login">

      {/* <!-- Background, click to close --> */}
      <a href="#" className="modal-bg" />

      {/* <!-- Modal  --> */}
      <ul className="modal tabs" role="tablist">
        <li className="modal-options">
          <input type="radio" name="tabs" id="tab1" checked />
          <label className="tab" for="tab1" role="tab" onClick={() => setChecked(false)}>Login</label>
          <div id="tab-content1" className="tab-content" role="tabpanel">
            <div className="field">
              <i className="far fa-envelope"></i>
              <input type="email" placeholder="Introduce tu correo" value={emailLogin} onChange={({target}) => { setEmailLogin(target.value);}} required />
            </div>
            <div className="field">
              <i className="fas fa-unlock-alt"></i>
              <input type="password" placeholder="Introduce tu contraseña" value={passLogin} onChange={({target}) => { setPassLogin(target.value);}} required />
            </div>
            <button type="submit" className="IniciarSesion btn" onClick={login}>Iniciar sesion</button>
          </div>
        </li>
      
        <li className="modal-options">
            <input type="radio" name="tabs" id="tab2" checked={checked} />
            <label className="tab" for="tab2" role="tab" onClick={() => setChecked(true)}>Register</label>
            <div id="tab-content2" className="tab-content" role="tabpanel">
              {/* <div className="field">
                <i className="far fa-user"></i>
                <input type="email" placeholder="Crea tu nombre de usuario" value={nameRegister} onChange={({target}) => { setNameRegister(target.value);}} required />
              </div> */}
              <div className="field">
                <i className="far fa-envelope"></i>
                <input type="email" placeholder="Introduce tu correo" value={emailRegister} onChange={({target}) => { setEmailRegister(target.value);}} required />
              </div>
              <div className="field">
                <i className="fas fa-unlock-alt"></i>
                <input type="password" placeholder="Introduce tu contraseña" value={passRegister} onChange={({target}) => { setPassRegister(target.value);}} required />
              </div>
              <div className="field">
                <i className="fas fa-user"></i>
                <input type="text" placeholder="Crea tu nombre de usuario" value={userName} onChange={({target}) => { setUserName(target.value.toLowerCase());}} required />
              </div>
              <button type="submit" className="IniciarSesion btn" onClick={register}>Registrarme</button>
            </div>
        </li>
      </ul>
    </div>
  );
}


export default Login;