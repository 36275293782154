import React from 'react';

export default function Filter() {
  return [
  {
    name: 'Python',
    values: [ 'python', 'py', 'pyw' ]
  },
  {
    name: 'JavaScript',
    values: [ 'javascript', 'js' ]
  },
  {
    name: 'React',
    values: [ 'react', 'jsx']
  },
  {
    name: 'Java',
    values: [ 'java' ]
  },
  {
    name: 'C++',
    values: [ 'c++', 'cpp' ]
  },
  {
    name: 'C',
    values: [ 'c' ]
  },
  {
    name: 'HTML',
    values: [ 'html', 'htm' ]
  },
  {
    name: 'CSS',
    values: [ 'css' ]
  },
  {
    name: 'PHP',
    values: [ 'php' ]
  },
  {
    name: 'SQL',
    values: [ 'sql' ]
  },
  {
    name: 'Ruby',
    values: [ 'ruby' ]
  },
  {
    name: 'Swift',
    values: [ 'swift' ]
  },
  {
    name: 'Go',
    values: [ 'go', 'golang' ]
  },
  ];
}


const x = [
  'Python',
  'React',
  'JavaScript', 'C++', 'C#', 'Java',
  'Go',
  'Rust',
  'PHP',
  'Ruby',
  'PyCharm'
];