import React from 'react';
import { Helmet } from 'react-helmet';

import construction from '../assets/images/construction.svg';

import '../styles/home.css';

function Snippets() {

  return (
    <div className="main">
      <Helmet>
        <link rel="canonical" href={`https://bacotips.com/snippets`} />
        <meta name="description" content="Encuentra el snippet que estas buscando."/>
        <meta name="keywords" content="javascript, python, react, node, c, c++, c#, java"/>
      
        <meta property="og:title" content="Snippets | BacoTips" />
        <meta property="og:site_name" content="Snippets"></meta>
        <meta property="og:image" content="https://bacotips.com/static/media/logo.0f01b891.svg" />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:description" content="Encuentra el snippet que estas buscando." />
      </Helmet>
      <img className="svg" src={construction} alt="" />
      <div className="description">
        <h1>Snippets</h1>
        <span>
          Sitio en construccion, puedes compartir tus ideas
          en el <a href="mailto:hello@bacotips.com">Correo Oficial</a>
          y tambien puedes ver los avances de diseño y
          maquetado dando click en los siguientes enlaces.
        </span><br />
        <div className="buttons">
          <a target="_blank" className="btn btn-purple"href="https://www.figma.com/proto/dZpXxZyGVAOiupuvi3Q8rR/Bacotips?scaling=min-zoom&node-id=107%3A8&starting-point-node-id=107%3A8">Version web</a>
          <a target="_blank" className="btn btn-white" href="https://www.figma.com/proto/dZpXxZyGVAOiupuvi3Q8rR/Bacotips?page-id=0%3A1&node-id=359%3A1297&viewport=241%2C48%2C0.73&scaling=min-zoom">Version movil</a>
        </div>
      </div>
  </div>
  );
}

export default Snippets;
