import React, { useState } from 'react';
import style from '../styles/saved.module.css';
import Filter from '../components/Filter.jsx';

import ToolTip from './ToolTip.jsx';

export default function ModalSaved(props) {
  const { state, setState } = props;
  const [action, setAction] = useState(1);
  const [canonicalValid, setCanonicalValid] = useState(false);
  const [uploadAll, setUploadAll] = useState(false);
  const [path, setPath] = useState('');
  const [currentTag, setCurrentTag] = useState('');
  const [description, setDescription] = useState('');
  const [tags, setTags] = useState([]);
  const [sugesstions, setSugesstions] = useState([]);
  const [files, setFiles] = useState([]);
  const tagNames = Filter();


  const onChangeHandler = (text) => {
    let matches = [];
    let inMatch = false;
    if(text.length > 0) {
      tagNames.forEach(tag => {
        tag.values.forEach(value => {
          if(value === text.toLowerCase()) {
            inMatch = true;
          }
          if(!matches.includes(tag.name) &&
              !tags.includes(tag.name) &&
              value.includes(text.toLowerCase())) {
            matches.push(tag.name);
          }
        });
      });
    }

    
    if(!inMatch && text.length > 0 && !tags.includes(text)) {
      setSugesstions([text, ...matches]);
    } else {
      setSugesstions(matches);
    }
    setCurrentTag(text);
  }

  const addTag = (tag) => {
    if(!tags.includes(tag)) {
      setTags(tags => [...tags, tag]);
    }
    setCurrentTag('');
    setSugesstions([]);
  }

  const removeTag = (id) => { setTags(tags.filter(tag => tag !== id)); }
  const removeFile = (id) => { setFiles(files.filter(file => file.id !== id)); }

  const uploadFiles = (target) => {
    let isUpload = false;
    for (let i = 0; i < target.files.length; i++) {
      // evita archivo duplicados
      if(files.filter(file => file.file.name === target.files[i].name).length === 0) {
        setFiles(files => [...files, {
          id: target.files[i].name,
          file: target.files[i],
          saved: false
        }]);
        isUpload = true;
      } 
    }

    if(!isUpload) { return; }

    setUploadAll(false);
    // limpia el input
    target.files = null;
  }


  const uploading = () => {
    setUploadAll(true);
    setFiles(files => files.map(file => {
      file.saved = true;
      return file;
    }));
  }


  return (
    <>
      { state &&
      <div className={style.overlay}>
        <style> {`body { overflow: hidden; }`} </style>
        <div className={style.modal}>
          <div className={style.modal_header}>
            <h2>Guardar Snippet</h2>
            <div className={style.close} onClick={() => setState(false)}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
              </svg>
            </div>
          </div>
          <div className={style.modal_body}>
            <div className={style.options}>
              <span className={style.radio + (action === 1 ? ' ' + style.active : '')} onClick={() => {setAction(1)}}>Publicar</span>
              <span className={style.radio + (action === 2 ? ' ' + style.active : '')} onClick={() => {setAction(2)}}>Borrador</span>
              <span className={style.radio + (action === 3 ? ' ' + style.active : '')} onClick={() => {setAction(3)}}>Oculto</span>
            </div>
            <div className={style.field_title}>
              <span className="text-gray">Titulo:</span>
              <span className={style.title}>Titulo de prueba</span>
              <ToolTip>El titulo se obtiene de forma automitaca.</ToolTip>
            </div>
            
            <div className={style.field_tag}>
              <input
                className={style.new_tag}
                type="text"
                onChange={e => onChangeHandler(e.target.value)}
                placeholder="Añade una etiqueta..."
                value={currentTag}
                />
              <div className={style.tags}>
                <ul>
                  {tags.map(tag => (
                    <li key={tag}>
                      # {tag}
                      <button className={style.remove} onClick={() => removeTag(tag)}>✖</button>
                    </li>
                  ))}
                </ul>
              </div>
              <div className={style.sugesstions}>
                {
                  sugesstions.map(suggestion => (
                    <span key={suggestion} onClick={() => addTag(suggestion)}># {suggestion}</span>
                  ))
                }
              </div>
            </div>
            
            <div className={style.field_path}>
              <span className="text-gray">
                bacotips.com/snippets/{path}
                <ToolTip>Puedes tomar posecion del link, siempre que este disponible.</ToolTip>
              </span>
              <div className={style.path_group}>
                <input type="text" placeholder="link" className={style.path}  value={path} onChange={e => setPath(e.target.value)}/>
                <span className={style.path_btn} onClick={() => setCanonicalValid(!canonicalValid)}>
                  { canonicalValid
                    ? <SuccessLink />
                    : <ErrorLink />
                  }
                </span>
              </div>
            </div>
            <div className={style.field_description}>
              <span className="text-gray">
                Descripcion: ({description.length}/125)
                <ToolTip>Esta descripcion sera visible en la tarjeta del snippet.</ToolTip>
              </span>
              <textarea
                placeholder="Escribe una descripcion..."
                className={style.textarea_edit} value={description} onChange={e => setDescription(e.target.value)}></textarea>
            </div>
            
            <div className={style.field_file}>
              <input className={style.new_file} type="file" multiple onChange={e => uploadFiles(e.target)} />
              <span className={style.upload_btn} onClick={() => uploading()}>
                  { uploadAll
                    ? <SuccessLink />
                    : <ReUpload />
                  }
                </span>
            </div>
            
            <div className={style.files}>
                <ul>
                  {files.map(file => (
                      <li key={file.id} className={file.saved ? style.file_saved : ''}>
                        {file.file.name}
                        <button className={style.remove} onClick={() => removeFile(file.id)}>✖</button>
                      </li>
                    ))}
                </ul>
              </div>
            <div className={style.field_info}>
              {
                action === 1
                ? <>Al publicar todos podran ver tu snippet desde la seccion de snippets.</>
                : action === 2
                ? <>Al guardarlo como borrador solo podras verlo tu, y publicarlo cuando este listo.</>
                : <>Al estar oculto solo podra verlo las personas que cuenten con el link.</>
              }
            </div>
            <button className={style.published + " btn btn-fuchsia"}>
            {
              action === 1
              ? <div>Publicar</div>
              : action === 2
              ? <div>Borrador</div>
              : <div>Oculto</div>
            }
            </button>
          </div>
        </div>
      </div>
      }
    </>
  );
}


function ErrorLink() {
  return (
    <svg className={style.path_btn_error} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
      <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
    </svg>
  )
}


function SuccessLink() {
  return (
    <svg className={style.path_btn_success} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
      <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
    </svg>
  )
}

function ReUpload() {
  return (
    <svg className={style.upload_btn_reload} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
      <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM6.354 9.854a.5.5 0 0 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 8.707V12.5a.5.5 0 0 1-1 0V8.707L6.354 9.854z"/>
    </svg>
  )
}


