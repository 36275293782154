import React, { useState, useEffect } from 'react';
import { auth, onAuthStateChanged  } from '../firebase/firebaseConfig';
import { collection, addDoc, setDoc, getDocs, getDoc, doc  } from "firebase/firestore";
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { db } from '../firebase/firebaseConfig';

// ImageKit
import { IKImage, IKContext, IKUpload } from 'imagekitio-react';

import Header from '../components/Header.jsx';
import Login  from '../components/Login.jsx';
import Footer from '../components/Footer.jsx';

import Home from './Home.jsx';
import Snippets from './Snippets.jsx';
import Profile from './Profile.jsx';
import Editor from './Editor.jsx';
import About from './About.jsx';

import '../styles/framework.css';
import styles from '../styles/index.module.css';


function App() {
  const [user, setUser] = useState(null);
  // required parameter to fetch images
  const urlEndpoint = 'https://ik.imagekit.io/bacotips';
  // private_4j7RmkUPNUYV8eu7y/PsdwUVR1Q=
  // optional parameters (needed for client-side upload)
  const publicKey = 'public_XfUNqth0yTf9oENjxY4hvBSym/8='; 
  const authenticationEndpoint = 'https://www.your-server.com/auth';

  useEffect(() => {
    onAuthStateChanged(auth, async (u) => {
      if(u) {
        getDoc(doc(collection(db, 'users'), u.uid)).then(doc => {
          //console.log(doc.data());
          u.profile = doc.data();
          console.log(u);
          setUser(u);
        });
      } else {
        setUser(u);
      }
    });
  }, []);

  return (
    <BrowserRouter>
      <div className={styles.container}>
        { !user && <Login  user={user} setUser={setUser}/> }
        <Header user={ user }/>
        {/* RUTAS */}
        <Routes>
          <Route path="/snippets" element={<Snippets />}  />
          <Route path="/donar" element={ <div> holi </div> } />
          <Route exact path="/about" element={ <About /> } />
          <Route exact path="/profile/:username"  element={ <Profile user={user} setUser={setUser}/> } />
          <Route exact path="/profile/:username/snippets"  element={ <Editor user={user} setUser={setUser}/> } />
          <Route exact path="/profile/:username/snippets/editor"  element={ <Editor user={user} setUser={setUser}/> } />
          <Route exact path="/profile"  element={ <Home user={user} /> } />
          {/* <Route path="/software" element={<Home />}  /> */}
          <Route exact path="/" element={<Home user={ user } />}  />
        </Routes>

      <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
