import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/home.css';
import image from '../assets/images/image.svg';
import presearch4 from '../assets/images/ban-4.jpg';
import presearch3 from '../assets/images/ban-3.jpg';
import { Helmet } from 'react-helmet';

import CardInfo from '../components/CardInfo.jsx';


function Home(props) {
  const { user } = props;
  
  return (
    <main className="main">
      <Helmet>
        <link rel="canonical" href={`https://bacotips.com/`} />
        <meta name="description" content="En BacoTips podrás encontrar porciones de código para implementarlo en tu proyecto en cuestión de minutos."/>
        <meta name="keywords" content="bacotips, snippet, snippets, codigo, software, javascript, python"/>
      
        <meta property="og:title" content="BacoTips" />
        <meta property="og:site_name" content="Baco Tips"></meta>
        <meta property="og:image" content="https://bacotips.com/static/media/logo.0f01b891.svg" />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:description" content="En BacoTips podrás encontrar porciones de código para implementarlo en tu proyecto en cuestión de minutos." />
      </Helmet>
      <section className="home-header sections">
        <div className="home-info">
          <span className="home-info-title">
            Encuentra el codigo <span className="text-blue">que necesitas</span>
          </span>
          <span className="home-info-description text-gray">
            <span className="highlight">BacoTips</span> es un sitio donde <h1 className="home-info-description-title">podrás encontrar porciones de código para implementarlo en tu proyecto en cuestión de minutos.</h1>
          </span>
          <div className="home-info-buttons">
            <Link to="/more" className="btn btn-purple">Conocer Más</Link>
            { !user && <a href="#login" className="btn btn-white">Iniciar sesion</a> }
          </div>
        </div>
        <div>
          <img src={image} className="image" alt="" />
        </div>
      </section>

      
      <section className="home-banner sections">
        <a href="https://www.presearch.org/signup?rid=3412546" target="_blank">
          <img src={presearch4} title="Presearch" alt="presearch" />
          <img src={presearch3} title="Presearch" alt="presearch" />
        </a>
      </section>

      <section className="home-recommendation sections">
        <span className="title">Recomendacion del dia</span>
        <span className="paragraph text-gray">Estamos trabajando en traer un sistema eficiente, pronto estara listo.</span>
      </section>

      <section className="home-published sections">
        <span className="title">Publicaciones recomendadas</span>
        <span className="paragraph text-gray published-description">Estas publicaciones son recomendadas segun tus gustos, lo que mas has visto y/o lo mas popular.</span>
        <span className="paragraph text-gray">Estamos trabajando en traer un sistema eficiente, pronto estara listo.</span>
      </section>

      <section className="home-bacotips sections">
        <span className="title">Más sobre BacoTips</span>
        <div className="cards-info">
          <CardInfo
            number={1}
            title="CREA"
            description="Crea fragmentos de codigo y compartelos con todo el mundo."
            />
          <CardInfo
            number={2}
            title="ENCUENTRA"
            description="Encuentra fragmentos de codigo he implementalos al instante."
            />
          <CardInfo
            number={3}
            title="COMPARTE"
            description="Comparte tu software y genera ingresos de forma pasiva."
            />
        </div>
      </section>

  </main>
  );
}

export default Home;
