import React, { useState, useEffect } from 'react';
import Editor from "@monaco-editor/react";
import ReactMarkdown from "react-markdown";
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';

import styles from '../styles/editor.module.css';
import md from '../styles/markDown.module.css';

import ModalSaved from '../components/ModalSaved.jsx';

export default function EditorSnippet() {
  const [editable, setEditable] = useState(true);
  const [saved, setSaved] = useState(false);
  const [code, setCode] = useState('');

  const options = {
    selectOnLineNumbers: false,
    automaticLayout: true,
    contextMenu: false,
    cursorBlinking: "smooth",
    wordWrap: "on",
    wrappingIndent: "same",
    scrollBeyondLastLine: false,
    fontFamily: "'Fira Code', monospace",
    fontFeatureSettings: "",
    minimap: {
      enabled: false
    }
  };

  useEffect(() => {} , []);

  const editorWillMount = (monaco) => {
    // monaco.editor.defineTheme('my-dark', {
    //   base: 'vs', 
    //   inherit: false,
    //   colors: {
    //     "editor.background": '#394555',
    //     "editor.foreground": '#e0e0e0',
    //   }
    // });
  }

  const isEditable = (boolean) => {
    setEditable(boolean);
    console.log(boolean);
  }
  // console.log(vscDarkPlus);

  return (
    <div>
      <span className={styles.title}>Nuevo Snippet</span>

      <div className={styles.options}>
        <button className={styles.btn_clicked} onClick={() => isEditable(true)}>Editor</button>
        <button className={styles.btn_clicked} onClick={() => isEditable(false)}>Vista previa</button>
        <button className={styles.btn_clicked + " " + styles.btn_saved} onClick={() => setSaved(true)}>Guardar cambios</button>
      </div>
      <ModalSaved state={saved} setState={setSaved} />
      <div>
        {
          editable ?
            <Editor
              width="100%"
              height="700px"
              theme="vs-dark"
              defaultLanguage="markdown"
              options={options}
              defaultValue={code}
              onChange={(newValue) => setCode(newValue)}
            />
          :
          <ReactMarkdown
            width="100%"
            height="500px"
            children={code}
            remarkPlugins={[remarkGfm]}
            rehypePlugins={[rehypeRaw]}
            className={md.reactMarkdown}
            components={{
              script: 'code', // evitar la injeccion de scripts
              code({node, inline, className, children, ...props}) {
                const match = /language-(\w+)/.exec(className || '')
                return !inline && match ? (
                  <SyntaxHighlighter
                    children={String(children).replace(/\n$/, '')}
                    style={vscDarkPlus}
                    language={match[1]}
                    // PreTag="div"
                    // {...props}
                  />
                ) : (
                  <code className={className} {...props}>
                    {children}
                  </code>
                )
              }
            }}
          />
        }
      </div>
    </div>
  )
}


