import React from 'react';
import '../styles/CardInfo.css';



const CardInfo = ({number=1, title="TITULO", description="VACIA"}) => {
  return (
    <div className={`box-card box-card-`+number}>
      <div className="box-content">
        <span className="box-number">{number}</span>
        <span className="box-title">{title}</span>
        <span className="box-description">{description}</span>
      </div>
    </div>
  );
}

export default CardInfo;