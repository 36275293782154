import React from 'react';
import { Helmet } from 'react-helmet';

import styles from '../styles/about.module.css';




export default function About() {
  return (
    <div className={styles.container}>
        <Helmet>
          <link rel="canonical" href="https://bacotips.com/about" />
          <title>¿Que es BacoTips? | BacoTips</title>
          <meta name="description" content="BacoTips es..." />
          
          <meta property="og:title" content="¿Que es BacoTips? | BacoTips" />
          <meta property="og:site_name" content="¿Que es BacoTips?"></meta>
          <meta property="og:image" content="https://bacotips.com/static/media/logo.0f01b891.svg" />
          <meta property="og:image:type" content="image/png" />
          <meta property="og:description" content="BacoTips es..." />
        </Helmet>
      <div className={styles.title}>¿Que es <span className="text-blue">Baco</span>Tips?</div>
      <div>
        BacoTips busca ser la biblioteca mas grande de snippets,
        aquí podrás encontrar fragmentos de código reusable para
        implementarlo en tu proyecto en cuestión de minutos.
      </div>
      <div>
        BacoTips es una herramienta que te ayudará a programar más y
        escribir menos, no gastes tiempo inventando soluciones que ya existen,
        encuentra los snippets que necesitas en nuestra biblioteca y aplícalos
        en tu proyectos.
      </div>
      <div>
        Si estás buscando soluciones para implementar en tu proyecto,
        o si quieres compartir tu propio snippet con la comunidad,
        visita nuestra sección de snippets.
      </div>
      <div>
        En BacoTips creemos en la colaboración, si ves que hay un snippet
        que puedes mejorar o agregar, no dudes en hacerlo.
      </div>
    </div>
  )
}
