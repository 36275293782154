import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { auth, db, onAuthStateChanged  } from '../firebase/firebaseConfig';
import { setDoc, doc, getDocs, getDoc, updateDoc, collection, query, where, limit } from "firebase/firestore";
// ImageKit
import { IKImage, IKContext, IKUpload } from 'imagekitio-react';
import {Helmet} from 'react-helmet';
// message
import Message from '../components/Message.jsx';

import styles from '../styles/user.module.css';


function Profile(props) {
  const { user } = props;
  const username = useLocation().pathname.split('/')[2]; 
  const [dataUser, setDataUser] = useState(null);
  const [editing, setEditing] = useState(false);
  // editing
  const [editingName, setEditingName] = useState('');
  const [editingUserName, setEditingUserName] = useState('');
  const [editingDescription, setEditingDescription] = useState('');

  // required parameter to fetch images
  const urlEndpoint = 'https://ik.imagekit.io/bacotips';
  const publicKey = 'public_XfUNqth0yTf9oENjxY4hvBSym/8='; 
  const authenticationEndpoint = 'https://bacotips.herokuapp.com/auth';
  const deleteEndpoint = 'https://bacotips.herokuapp.com/delete?fileId=';
  
  useEffect(() => {
    getUser();
  }, [username]);

  const getUser = async () => {
    const q = query(collection(db, "users"), where("userName", "==", username));
    const r = await getDocs(q);
    r.forEach(doc => {
      setDataUser(doc.data());
      setEditingName(doc.data().name);
      setEditingUserName(doc.data().userName);
      setEditingDescription(doc.data().description);
    });
  }


  const onError = err => {
    console.log("Error", err);
    Message("Error al tratar de subir la imagen.", "err", "error");
  };

  const onSuccessBanner = async res => {
    // borrar imagen anterior de imagekit
    fetch(deleteEndpoint + dataUser.banner.fileId);

    // actualizar en base de datos
    onAuthStateChanged(auth, (u) => {
      if(u) {
        updateDoc(doc(db, "users", u.uid), {
          banner: {
            uri: res.filePath, // url del banner
            fileId: res.fileId,
            transformations: []
          }
        });
      } else {
        // error, no se reconoce el usuario
        Message("No pudimos validar tu cuenta, por favor recarga la pagina.", "err", "error");
      }
    });
    setDataUser({
      ...dataUser,
      banner: {
        uri: res.filePath, // url del banner
        fileId: res.fileId,
        transformations: []
      }
    });

    Message("Imagen subida correctamente.", res.fileId, "success");
  };

  const onSuccessProfile = res => {
    // borrar imagen anterior de imagekit
    fetch(deleteEndpoint + dataUser.photo.fileId);


    // actualizar en base de datos
    onAuthStateChanged(auth, (u) => {
      if(u) {
        updateDoc(doc(db, "users", u.uid), {
          photo: {
            uri: res.filePath, // url del banner
            fileId: res.fileId,
            transformations: []
          }
        });
      } else {
        // error, no se reconoce el usuario
        Message("No pudimos validar tu cuenta, por favor recarga la pagina.", "err", "error");
      }
    });
    setDataUser({
      ...dataUser,
      photo: {
        uri: res.filePath, // url del banner
        fileId: res.fileId,
        transformations: []
      }
    });
    
    Message("Imagen subida correctamente.", res.fileId, "success");
  };

  const profileEditing = () => {
    setEditing(true);
  }
  
  const profileSaveEditing = async () => {
    let exists = false;
    if(editingUserName.length < 6 && editingUserName !== 'baco') {
      Message("El nombre de usuario debe tener al menos 6 caracteres.", "err", "error");
      return;
    }
    // revisar si el nombre de usuario no esta en uso
    if(editingUserName !== username) {
      const q = query(collection(db, "users"), where("userName", "==", editingUserName));
      const r = await getDocs(q);
      r.forEach(doc => {
        if(doc.exists) {
          exists = true;
          Message("El nombre de usuario ya esta en uso.", "err", "error");
          return;
        }
      });
    }

    if(!exists) {
      Message("Cambios guardados correctamente.", "success", "success");
      setEditing(false);
      // editar base de datos
      onAuthStateChanged(auth, (u) => {
        if(u) {
          updateDoc(doc(db, "users", u.uid), {
            name: editingName,
            userName: editingUserName,
            description: editingDescription
          });
          setDataUser({
            ...dataUser,
            name: editingName,
            userName: editingUserName,
            description: editingDescription
          });
        } else {
          // error, no se reconoce el usuario
          Message("No pudimos validar tu cuenta, por favor recarga la pagina.", "err", "error");
        }
      });
    }
  }

  const profileCancelEditing = () => {
    setEditing(false);
    setEditingName(dataUser.name);
    setEditingUserName(dataUser.userName);
    setEditingDescription(dataUser.description);
    Message("Los cambios no se han guardado.", "success", "info");
  }

  const onChangeName = (e) => {
    setEditingName(e.target.value);
  }

  const onChangeUserName = (e) => {
    setEditingUserName(e.target.value.toLowerCase());
  }

  const onChangeDescription = (e) => {
    setEditingDescription(e.target.value);
  }

  return (
    <>
    <Helmet>
      <link rel="canonical" href={`https://bacotips.com/profile/${username}`} />
    </Helmet>
    {
      dataUser
      ?
      <div>
        <Helmet>
          <title>{username} | BacoTips</title>
          <meta name="description" content={dataUser.description} />
          
          <meta property="og:title" content={`${username} | BacoTips`} />
          <meta property="og:site_name" content={username}></meta>
          <meta property="og:image" content={`${urlEndpoint}${dataUser.photo.uri}`} />
          <meta property="og:image:type" content="image/png" />
          <meta property="og:description" content={dataUser.description} />
        </Helmet>
        <div className={styles.profile}>
          <div className={styles.profile_banner}>
            <IKContext 
              publicKey={publicKey} 
              urlEndpoint={urlEndpoint}
              authenticationEndpoint={authenticationEndpoint} >
              <IKImage path={dataUser.banner.uri} className={styles.banner}/>
              {
                user && username === user.profile.userName &&
                <label className={styles.banner_edit + ' fas fa-camera'}>
                  <IKUpload
                    onError={onError}
                    onSuccess={onSuccessBanner}
                    className={styles.display_none}
                    folder={"/banners"}
                    name="banner"
                  />
                </label>
              }
            </IKContext>
          </div>
          <div className={styles.profile_avatar}>
            <IKContext 
              publicKey={publicKey} 
              urlEndpoint={urlEndpoint}
              authenticationEndpoint={authenticationEndpoint} >
              <IKImage path={dataUser.photo.uri} className={styles.avatar}/>
              {
                user && username === user.profile.userName &&
                <label className={styles.avatar_edit + ' fas fa-camera'}>
                  <IKUpload
                      onError={onError}
                      onSuccess={onSuccessProfile}
                      className={styles.display_none}
                      folder={"/profiles"}
                      name="banner"
                    />
                </label>
              }
            </IKContext>
          </div>
          <div className={styles.profile_info}>
            <span className={styles.profile_name}>{dataUser.name}</span>
            <span className={styles.profile_userName + ' text-gray'}>@{dataUser.userName} {dataUser.verified && <i class="fas fa-certificate text-blue"></i>}</span><br />
            <span className={styles.description}>{dataUser.description}</span><br />
            
          </div>
        </div>
        <div className={styles.profile_edit}>
          {
            user && username === user.profile.userName &&
            <a className="navbar-link btn btn-purple" onClick={profileEditing}>Editar perfil</a>
          }
        </div>

        {
          editing &&
          <div className={styles.data_edit}>
            <div className={styles.field_edit}>
              <label className={styles.label_edit}>Tu cuenta</label>
            </div>
            <div className={styles.field_edit}>
              <label className={styles.label_edit}>Nombre</label>
              <input className={styles.input_edit} type="text" value={editingName} onChange={onChangeName}/>
            </div>
            <div className={styles.field_edit}>
              {/* nombre de usuario */}
              <label className={styles.label_edit}>Nombre de usuario <sup className="text-gray">(min 6 caracteres)</sup></label>
              <input className={styles.input_edit} type="text" value={editingUserName} onChange={onChangeUserName}/>
            </div>
            <div className={styles.field_edit}>
              <label className={styles.label_edit}>Descripción</label>
              <textarea className={styles.textarea_edit} value={editingDescription} onChange={onChangeDescription}/>
            </div>
            <div className={styles.saving_edit}>
              <a className="navbar-link btn btn-red" onClick={profileCancelEditing}>Cancelar cambios</a>
              <a className="navbar-link btn btn-purple" onClick={profileSaveEditing}>Guardar cambios</a>
            </div>
            
          </div>
        }

      </div>
      :
      <div>
        
        <Helmet>
          <title>Usuario inexistente | BacoTips</title>
          <meta name="description" content="Crea tu cuenta de usuario."></meta>
        </Helmet>
        <div className={styles.profile}>
          <div className={styles.profile_banner}>
            <IKContext 
              publicKey={publicKey} 
              urlEndpoint={urlEndpoint}
              authenticationEndpoint={authenticationEndpoint} >
              <IKImage path='/banners/banner-default.jpg' className={styles.banner}/>
            </IKContext>
          </div>
          <div className={styles.profile_avatar}>
            <IKContext 
              publicKey={publicKey} 
              urlEndpoint={urlEndpoint}
              authenticationEndpoint={authenticationEndpoint} >
              <IKImage path='/profiles/avatar-default.png' className={styles.avatar}/>
            </IKContext>
          </div>
          <div className={styles.profile_info}>
            <span className={styles.profile_name}>Este usuario no existe</span>
            <span className={styles.profile_userName + ' text-gray'}>@{username}</span><br />
            {
              !user &&
              <span className={styles.description}>
                aun no existe una cuenta con este nombre de usuario,
                crea una cuenta ahora mismo y adueñate de este perfil.
              </span>
            }
            
          </div>
        </div>
      </div>
    }
    </>
  );
}

export default Profile;
