import React from 'react';
import { auth } from '../firebase/firebaseConfig';

// ImageKit
import { IKImage, IKContext } from 'imagekitio-react';

import { Link } from 'react-router-dom';
import Message from '../components/Message.jsx';
import logo from '../assets/icons/logo.svg';
import style from '../styles/header.module.css';



export default function Header(props) {
  const { user } = props;
    // required parameter to fetch images
    const urlEndpoint = 'https://ik.imagekit.io/bacotips';

  function openBlog() {
    window.open("https://blog.bacotips.com/");
  }

  const logOut = () => {
    auth.signOut();
    Message('Sesión cerrada.', 'sing out', 'success');
    if(!user.user) { console.log(user); }
    // getDoc(doc(collection(db, 'users'), user.uid)).then(doc => {
    //   console.log(doc.data());
    // });
  }

  return (
      <header className={style.header}>
        <Link to="/" className={style.navbar_link + " link " + style.navbar_logo }>
          <img className={style.header_logo} src={logo} alt="" />
        </Link>

        <nav className={style.dropdown_menu + " " + style.navbar} role="navigation">
            <span className={style.movil}>
              <span className="text-blue">Baco</span><span className="text-white">Tips</span>
              &nbsp;<i className="fas fa-caret-down text-white"></i>
            </span>


            <div className={style.dropdown_content_menu}>
              <Link to="/" className={style.no_movil + " link"}>
                <span className="text-blue">Baco</span><span className="text-white">Tips</span>
              </Link>
              <Link to="/snippets" className={style.navbar_link + " link text-gray"}>Snippets</Link>
              {/* <div className="navbar-link dropdown text-gray">
                <span className="link">Software&nbsp;<i className="fas fa-caret-down"></i></span>
                <div className="dropdown-content">
                  <Link to="/donar">Todo</Link>
                  <Link to="/donar">Punto de venta moderno</Link>
                  <Link to="/donar">Banner para correos</Link>
                  <Link to="/donar">API - Hub</Link>
                  <Link to="/donar">BlockChain</Link>
                  <Link to="/donar">BTC - minner</Link>
                </div>
              </div> */}
              <div className={style.navbar_link + " link text-gray"} onClick={openBlog}>Blog</div>
              <Link to="/donar" className={style.navbar_link + " btn btn-purple"}>Donar</Link>
              <div className={style.no_movil}></div>
            </div>
        </nav>

        <div className={style.navbar_link + " text-gray " + style.dropdown}>
          <i className="fas fa-user-circle fa-2x"></i>
            {
              user
                ? 
                  <div className={style.dropdown_content + " " + style.dropdown_user}>
                    <Link to={`/profile/${user.profile.userName}`} role="button" id={style.view_profile}>
                      <IKContext urlEndpoint={urlEndpoint}>
                        <IKImage path={user.profile.photo.uri} className={style.header_logo}/>
                      </IKContext>
                      <div>
                        <span className={style.view_profile__name}>{user.profile.name}</span>
                        <span className={style.view_profile__view + " text-gray"}>Ver tu perfil</span>
                      </div>
                    </Link>
                    <hr className="hr"/>
                    <Link to={`/profile/${user.profile.userName}/snippets/editor`} className={style.navbar_link + " link text-gray"}>
                      <New />
                      Nuevo Snippet
                    </Link>
                    <Link to="/notifications" role="button">
                      {user.profile.notifications.length > 0 ? <NotificationActive /> : <Notification />}
                      Notificaciones ({user.profile.notifications.length})
                    </Link>
                    <Link to="/favorites" role="button">
                      <BookMark />
                      Favoritos ({user.profile.favorites.length})
                    </Link>
                    <Link to="/problems" role="button">
                      <Notice />
                      Avisar sobre un problema
                    </Link>
                    <a role="button" onClick={logOut}>
                      <Out />
                      Cerrar Sesion
                    </a>
                  </div>
                : 
                  <div className={style.dropdown_content + " " + style.dropdown_login}>
                    <a href="#login" role="button">Iniciar Sesion</a>
                  </div>
            }
            
        </div>
      </header>
  );
}


function New() {
  return (
    <svg class={style.icons} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
      <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z"/>
    </svg>
  );
}

function Notification() {
  return (
    <svg class={style.icons} width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 20C6.89605 19.9946 6.00092 19.1039 5.99 18H9.99C9.99211 18.2674 9.94113 18.5325 9.84 18.78C9.57766 19.382 9.04181 19.8211 8.4 19.96H8.395H8.38H8.362H8.353C8.23682 19.9842 8.11865 19.9976 8 20ZM16 17H0V15L2 14V8.5C1.94732 7.08912 2.26594 5.68913 2.924 4.44C3.57904 3.28151 4.6987 2.45888 6 2.18V0H10V2.18C12.579 2.794 14 5.038 14 8.5V14L16 15V17Z" fill="white"/>
    </svg>
  );
}

function NotificationActive() {
  return (
    <svg class={style.icons} width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 20.0002C6.89543 20.0002 6 19.1048 6 18.0002H10C10 19.1048 9.10457 20.0002 8 20.0002ZM16 17.0002H0V15.0002L2 14.0002V8.50023C1.94732 7.08935 2.26594 5.68935 2.924 4.44023C3.57904 3.28174 4.6987 2.45911 6 2.18023V0.000227844H9.646C8.34639 1.45266 8.12949 3.57663 9.10869 5.26177C10.0879 6.94692 12.0406 7.81016 13.946 7.40023C13.981 7.75723 13.998 8.12723 13.998 8.50023V14.0002L15.998 15.0002V17.0002H16ZM13 6.00023C11.4097 5.99776 10.0977 4.75478 10.0093 3.16695C9.92091 1.57912 11.0869 0.198215 12.6671 0.0192818C14.2473 -0.159651 15.6926 0.925571 15.9615 2.49296C16.2304 4.06034 15.2295 5.56523 13.68 5.92323C13.457 5.97446 13.2288 6.00029 13 6.00023Z" fill="white"/>
    </svg>
  );
}

function BookMark() {
  return (
    <svg class={style.icons} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
      <path d="M2 2v13.5a.5.5 0 0 0 .74.439L8 13.069l5.26 2.87A.5.5 0 0 0 14 15.5V2a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2z"/>
    </svg>
  );
}

function Notice() {
  return (
    <svg class={style.icons} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
      <path d="M2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
    </svg>
  );
}

function Out() {
  return (
    <svg class={style.icons} width="24" height="19" viewBox="0 0 24 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M23.6678 9.95389L15.6674 17.9543C14.9531 18.6686 13.715 18.1686 13.715 17.1447V12.5731H7.23845C6.60509 12.5731 6.09554 12.0635 6.09554 11.4302V6.8585C6.09554 6.22513 6.60509 5.71558 7.23845 5.71558H13.715V1.14393C13.715 0.124829 14.9484 -0.379958 15.6674 0.334363L23.6678 8.33476C24.1107 8.7824 24.1107 9.50625 23.6678 9.95389ZM9.14331 17.7162V15.8113C9.14331 15.497 8.88616 15.2399 8.57185 15.2399H4.57166C3.72876 15.2399 3.04777 14.5589 3.04777 13.716V4.57267C3.04777 3.72977 3.72876 3.04878 4.57166 3.04878H8.57185C8.88616 3.04878 9.14331 2.79163 9.14331 2.47733V0.57247C9.14331 0.258168 8.88616 0.00101284 8.57185 0.00101284H4.57166C2.04772 0.00101284 0 2.04873 0 4.57267V13.716C0 16.2399 2.04772 18.2876 4.57166 18.2876H8.57185C8.88616 18.2876 9.14331 18.0305 9.14331 17.7162Z" fill="white"/>
    </svg>
    
  );
}