import React from 'react';
import { Link } from 'react-router-dom';
import styles from '../styles/footer.module.css';



function Footer() {
  return (
      <footer className={styles.footer}>
        <div className={styles.site_info}>
          <Link to="/"           className="link text-gray">Inicio</Link>
          <Link to="/snippets"   className="link text-gray">Snippets</Link>
          {/* <Link to="/software"   className="link text-gray">Software</Link> */}
          <Link to="/contact"    className="link text-gray">Contacto</Link>
          <Link to="/about"    className="link text-gray">Nosotros</Link>
          <Link to="/privacy"    className="link text-gray">Politicas de privacidad</Link>
          <Link to="/Conditions" className="link text-gray">Terminos y condiciones</Link>
        </div>
        <div className={styles.site_description + ' text-white'}>© 2021 BacoTips. todos los derechos reservados</div>
      </footer>
  );
}

export default Footer;